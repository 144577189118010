import request from "@/api/request"

// 评价列表
export function jobEvaluationSearch(data) {
  return request({
    method: 'get',
    url: '/main/oauth/jobEvaluation/pageExcellent',
    params:data
  })
}

// 个人/班组评价列表
export function jobEvaluationData(data) {
  return request({
    method: 'get',
    // url: '/main/jobEvaluation/page',
    url: '/main/oauth/jobEvaluation/pageSuggest',
    params:data
  })
}

// 劳务评价列表
export function jobEvaluationOrg(data) {
  return request({
    method: 'get',
    // url: '/main/jobEvaluationOrg/page',
    url: '/main/oauth/jobEvaluation/pageSuggestOrg',
    params:data
  })
}

// 评价详情
export function jobEvaluationDetail(data) {
  return request({
    method: 'get',
    url: '/main/oauth/jobEvaluation/detailOne',
    params:data
  })
}

// 劳务评价详情
export function jobEvaluationInfo(data) {
  return request({
    method: 'get',
    url: '/main/oauth/jobEvaluation/detailEvOrg',
    params:data
  })
}

// 招聘会用户申请
export function userApply(data) {
  return request({
    method: 'post',
    url: '/main/jobFairUserApply/userApply',
    data:data
  })
}
export function getBCSGrad(data) {
  return request({
    method: 'get',
    url:'/main/oauth/getBCSGradeByIdCard',
    params:data
  })
}

//获取当前登录用户
export function getLoginUser(data) {
  return request({
    method: 'get',
    url:'/jobUser/jobUser/getLoginUser',
    params:data
  })
}
//获取当前登录用户信息
export function getLoginUserInfo(data) {
  return request({
    method: 'get',
    url:'jobUser/jobUser/getLoginUser2',
    params:data
  })
}

//  重新评价列表
export function jobEvaluationPage (data) {
  return request({
    method: 'get',
    url: '/main/jobEvaluationInfo/page',
    params: data
  })
}

// 重新审核详情
export function jobEvaluationList (data) {
  return request({
    method: 'get',
    url: '/main/jobEvaluationInfo/detail',
    params: data
  })
}

// 新增重新审核
export function jobEvaluationAdd (data) {
  return request({
    url: '/main/jobEvaluationInfo/add',
    method: 'post',
    data: data
  })
}
// 评分认证信息列表
export function jobScoringList (data) {
  return request({
    method: 'get',
    url: '/job/jobEvaluation/evaluationListInfo',
    params: data
  })
}

// 添加评价材料信息
export function jobScoringAdd (data) {
  return request({
    method: 'post',
    url: '/job/jobEvaluationData/add',
    data: data
  })
}
// 删除评价材料信息
export function jobScoringDelete (data) {
  return request({
    method: 'post',
    url: '/job/jobEvaluationData/delete',
    data: data
  })
}
// 评价材料全部提交
export function jobScoringSave (data) {
  return request({
    method: 'post',
    url: '/job/jobEvaluationData/save',
    data: data
  })
}
// 华为云上链信息
export function getBCSGradeByIdCard(data) {
  return request({
    method: 'get',
    url:'/main/oauth/getBCSGradeByIdCardHistory',
    params:data
  })
}
